<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <h4>Search Organisation</h4>
            </b-col>
            <b-col class="text-right">
              <b-button variant="primary" size="sm" squared @click="addOrg">Add New Organisation</b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <label>Search</label>
              <b-form-input v-model="searchTerm" @keyup="search"></b-form-input>
            </b-col>       
          </b-row>      
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <b-card>
          <b-row>
            <b-col class="12">            
              <b-table striped hover
                       sort-icon-left
                       :items="tableData.dataSource" 
                       :fields="tableData.tableColumns"
                       :busy="tableData.isLoading" 
                       @row-clicked="openorganisation"
                       :per-page="tableData.resultsPerPage"
                       id="organisationSearch"
                       :current-page="tableData.currentPage">

                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
                </template>

                <template #cell(actions)="row">
                  <b-row align-v="center" align-h="end">
                    <b-button @click="openorganisation(row.item)" size="sm" class="btn-icon">
                      <b-icon-chevron-right></b-icon-chevron-right>
                    </b-button>
                  </b-row>
                </template>

                <template #cell(logo)="row">
                 <img v-if="row.item.logo != null && row.item.logo != ''" width="50" v-bind:src="url + row.item.logo"/>
                </template>

              </b-table>
              <b-row align-h="center" >
                <b-pagination
                    v-model="tableData.currentPage"
                    :total-rows="rows"
                    :per-page="tableData.resultsPerPage"
                    aria-controls="organisationSearch"
                ></b-pagination>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>
<style >
.primary{
background-color: #ffcb14;
color: #222222;
}
.primary:hover{
    color: #fff;
}
.page-item.active .page-link {
    z-index: 3;
    color: #222222;
    background-color: #ffcb14;
    border-color: #ffcb14;
}
img {
  min-height: 45px;  
  max-height: 45px;
  min-width: 45px;
  max-width: 45px;
}
</style>
<script>
import {mapMutations} from "vuex";
import api from "../../../api";

export default {
  name: "organisationSearch",
  data: () => ({
    tableData: {
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Name',
          key: 'name',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Logo',
          key: 'logo',
          sortable: true,
          tdClass: '',
        },
       
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: ''
        }
      ]
    },

    organisations: [],
    searchTerm: "",
      url: ""
  }),
  created() {
    this.url = api.Url
    this.setBreadcrumb([
      {
        text: 'Organisations'
      },
    ]);
    if (!Array.prototype.last){
      Array.prototype.last = function(){
        return this[this.length - 1];
      };
    }

   const s = (data) => {
      this.tableData.dataSource = data;
      this.organisations = data;
    // console.log(this.organisations)
     //this.organisations.forEach(x => x.logo = x.logo.split('/').last())
     // console.log(data);
       this.tableData.isLoading = false;
   };

   const e = (msg) => {
     console.log(msg);
   };

    api.getAllOrganisation(s, e);
  },
  
  methods: {
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),

    search() {
      this.tableData.dataSource = this.organisations.filter((x) => 
      x.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );  
    },
    
    openorganisation(organisations) {
      localStorage.setItem("view_organisation_id", organisations.id);
      this.$router.push({path: '/admin/ManageOrganisations/OrganisationView'})
    },
    
    addOrg() {
      this.$router.push({path: '/admin/ManageOrganisations/CreateOrganisation'})
    },
  },
  computed: {
      rows() {
          return this.tableData.dataSource.length
      },
  },
}
</script>

<style scoped>

</style>